'use strict';

require('velocity-animate');

// Constructor
var CookieAlert = function() {

  var self = this;

  self.config = {
    'alert'  :  $('.cookie-alert'),
    'close'  :  $('#cookieAlertClose')
  }

  var $alert = self.config.alert;
  var $close = self.config.close;


  self.localStorageHandler = function() {

    if (! localStorage['cookie_alert_done']) {

      setTimeout(function () {
        self.showAlert();
        self.clickHandler();
        localStorage['cookie_alert_done'] = true;
      }, 2000);
    }
  }


  self.clickHandler = function() {

    $close.on('click', function(e) {
      e.preventDefault();
      self.hideAlert();
    })
  }


  self.showAlert = function() {

    $alert.velocity(
      {
        translateY: ['0', '100%']
      },
      {
        display: 'block'
      }
    );
  }


  self.hideAlert = function() {

    $alert.velocity(
      {
        translateY: '100%'
      },
      {
        display: 'none'
      }
    );
  }


  self.init = function() {
    self.localStorageHandler();
  }
};

module.exports = CookieAlert;
