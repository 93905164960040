'use strict';

var DesktopHighlights = function($item, $container, $content, linkedTemplate, mapEmbed) {

  var self = this;


  self.parseItems = function() {

    $item.each(function() {
      var $this = $(this);
      var _$container = $this.find($container);
      var _$content = $this.find($content);
      var _content = JSON.parse(_$content.text());

      if (_$container.attr('data-highlight-type') === 'linked') {
        self.appendHighlight(_$container, _content);
      } else if (_$container.attr('data-highlight-type') === 'map') {
        // self.appendMap(_$container);
      }
    });
  }


  self.appendHighlight = function($container, content) {
    var $highlight = linkedTemplate(content);

    if ($container.children().length === 0) {
      $container.append($highlight);
    }
  }


  self.appendMap = function($container) {

    if ($container.children().length === 0) {
      $container.append(mapEmbed);
    }
  }


  self.init = function() {
    self.parseItems();
  }
};

module.exports = DesktopHighlights;
