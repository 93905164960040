'use strict';

var Constants = require('../../constants/constants');

var MobileSubnavs = function($navItem, $link, $subnav, $inner) {

  var self = this;


  self.parseNavItems = function() {

    $navItem.each(function() {

      var $this = $(this);
      var _$link = $this.find($link);
      var _$subnav = $this.find($subnav);
      var _$inner = $this.find($inner);

      self.clickHandler(_$link, _$subnav, _$inner);
    });
  }


  self.clickHandler = function(_$link, _$subnav, _$inner) {

    _$link.on('click.mobileSubnavs', function(e) {
      e.preventDefault();

      var visibility = function() {
        self.subnavVisibility(_$subnav, _$inner);
      };

      if ($subnav.not(_$subnav).hasClass('is-visible') && !$subnav.hasClass('velocity-animating')) {
        self.hideSubnav($subnav, $inner, visibility);
      } else if (!$subnav.hasClass('velocity-animating')) {
        visibility();
      }
    });
  }


  self.subnavVisibility = function(_$subnav, _$inner) {

    if (_$subnav.hasClass('is-visible') && !_$subnav.hasClass('velocity-animating')) {
      self.hideSubnav(_$subnav, _$inner);
    } else if (!_$subnav.hasClass('velocity-animating')) {
      self.showSubnav(_$subnav, _$inner);
    }
  }


  self.hideSubnav = function(_$subnav, _$inner, cb) {

    _$subnav.removeClass('is-visible');
    _$inner.velocity(
      {
        opacity: 0
      }
    );
    _$subnav.velocity(
      'slideUp',
      {
        easing: 'easeOutQuart',
        complete: function() {
          if (typeof cb === 'function') {
            cb();
          }
        }
      }
    );
  }


  self.showSubnav = function(_$subnav, _$inner) {

    _$subnav.addClass('is-visible');
    _$subnav.velocity(
      'slideDown',
      {
        easing: 'easeOutQuart'
      }
    );
    _$inner.velocity(
      {
        opacity: 1
      }
    );
  }


  self.reset = function() {

    $link.off('click.mobileSubnavs');
    $subnav.removeClass('is-visible');
    $subnav.removeAttr('style');
    $inner.removeAttr('style');
  }


  self.init = function() {
    self.parseNavItems();
  }
};

module.exports = MobileSubnavs;
