'use strict';

require('../_modules/modernizr/modernizr');
require('../_modules/promise-polyfill/promise-polyfill');
var FontFaceObserver = require('fontfaceobserver');
require('velocity-animate');
require('sticky-kit/dist/sticky-kit');

// slick.js
// https://github.com/kenwheeler/slick/issues/1010#issuecomment-124861077
require('../_modules/slick-carousel-browserify-fix/slick-carousel-browserify-fix');

var MainHeader = require('../_modules/main-header/main-header');
var Hero = require('../_modules/hero/hero');
var StickySidebar = require('../_modules/sticky-sidebar/sticky-sidebar');
var Modal = require('../_modules/modal/modal');
var Pswp = require('../_modules/pswp/pswp');
var MobileActions = require('../_modules/mobile-actions/mobile-actions');
var MasonryGrid = require('../_modules/masonry-grid/masonry-grid');
var CookieAlert = require('../_modules/cookie-alert/cookie-alert');
var Claim = require('../_modules/claim/claim');

$(function() {

  // https://www.filamentgroup.com/lab/font-events.html
  // https://github.com/bramstein/fontfaceobserver
  var fontA = new FontFaceObserver('Helvetica Neue');
  var fontB = new FontFaceObserver('Helvetica Neue Bold Condensed');
  var fontC = new FontFaceObserver('Helvetica Neue', {
    weight: 300
  });

  Promise.all([fontA.load(), fontB.load(), fontC.load()]).then(function () {
    document.documentElement.className += " fonts-loaded";
  });

  new MainHeader().init();
  new Hero().init();
  new StickySidebar().init();
  new Modal().init();
  new Pswp().init();
  new MobileActions().init();
  new MasonryGrid().init();
  new CookieAlert().init();
  new Claim().init();
});


function removeSpecificFilterFunction(filter) {

    var filter_id = $(filter).attr('filter_id');
    var url = window.location.href.split('?')[0];
    var array = url.split('/');
    var finalUrl = url;
    //for (var i = 1; i < 6 && i < array.length; i++) finalUrl += "/" + array[i];
    
    var array_deletions = new Array();
    

    var joinChar = "?";
    $('.filters__active').first().children().children().each(function() {
        var contFilter = true;
        for (var j = 0; j < array_deletions.length && contFilter; j++) {
           if (array_deletions[j] == $(this).attr('filter') + "=" + $(this).attr('filter_id')) contFilter = false;
        }
        if (contFilter && filter_id != $(this).attr('filter_id') ) {
           finalUrl = finalUrl + joinChar + $(this).attr('filter') + "=" + $(this).attr('filter_id');
           joinChar = "&";
        }
    });
    window.location.href = finalUrl;
}


(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

//Si esta a testweb no carrega analytics ni tampoc quan es carrega de test
if (window.location.href.indexOf("http://testweb.cccb.org/") == -1 &&
		window.location.href.indexOf("/testweb/") == -1) {
	ga('create', 'UA-5823913-26', 'auto');
	ga('send', 'pageview');
}


$('.filter-module--desktop__selected-item').click(function(){ removeSpecificFilterFunction($(this)); return false; });
