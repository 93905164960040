'use strict';

var Constants = require('../constants/constants');

// Constructor
var MobileActions = function() {

  var self = this;

  self.config = {
    'actions'          :  $('[data-mobile-actions-element]'),
    'mobileContainer'  :  $('#mobileActionsContainer')
  }

  var $actions = self.config.actions;
  var $mobileContainer = self.config.mobileContainer;


  self.cloneActions = function() {

    if ($mobileContainer.children().length === 0) {
      var $clone = $actions.clone().addClass('is-clone');

      $mobileContainer.append($clone);
    }
  }


  self.init = function() {

    var smallViewports = function() {
      self.cloneActions();
    };

    new Constants().MATCHMEDIA_HANDLER('TABLET', null, smallViewports);
  }
};

module.exports = MobileActions;
