'use strict';

var Constants = require('../../constants/constants');

var DesktopSticky = function($header) {

  var self = this;
  var scrollDistance = $header.height();
  var $window = $(window);
  var $mainContent = $('#mainContent');


  self.scrollListener = function() {
    $window.on('scroll.stickyHeader', self.foldHandler);
  }


  self.foldHandler = new Constants().THROTTLE(function() {

    var st = $window.scrollTop();

    if (st > scrollDistance) {
      self.fold();
    } else {
      self.unfold();
    }
  }, 150)


  self.fold = function() {

    if (! $header.hasClass('is-folded')) {

      $mainContent.css('padding-top', $header.height());
      $header.addClass('is-folded');
      $header.velocity('stop');
      $header.velocity(
        {
          translateY: '-100%',
          opacity: '0'
        },
        {
          duration: 0,
          complete: function() {
            $header.velocity(
              {
                translateY: '0',
                opacity: '1'
              },
              {
                duration: '160ms'
              }
            );
          }
        }
      );
    }
  }


  self.unfold = function() {

    if ($header.hasClass('is-folded')) {

      $header.velocity('stop');
      $header.velocity(
        {
          translateY: '-100%',
          opacity: '0'
        },
        {
          duration: '160ms',
          complete: function() {
            $header.removeClass('is-folded');
            $mainContent.css('padding-top', '0');
            $header.velocity(
              {
                translateY: '0',
                opacity: '1'
              },
              {
                duration: '160ms'
              }
            );
          }
        }
      );
    }
  }


  /*
  self.reset = function() {

    $window.off('scroll.stickyHeader');
    $header.removeClass('is-folded');
    $header.removeAttr('style');
    $mainContent.removeAttr('style');
  }
  */


  self.init = function() {
    self.scrollListener();
  }
};

module.exports = DesktopSticky;
