'use strict';

// var Constants = require('../../constants/constants');

var SearchModal = function($open, $close, $modal, $inner, $input, subnavIsOpenClass, closeMainNavCb) {

  var self = this;
  var $html = $('html');


  self.clickHandler = function($button) {

    $button.on('click', function() {

      // var $this = $(this);

      // new Constants().ARIA_EXPANDED_TOGGLE($this);

      self.modalVisibility();
    });
  }


  self.modalVisibility = function() {

    if ($modal.hasClass('is-visible')) {
      self.hideModal();
    } else {
      self.showModal();
    }
  }


  self.showModal = function() {

    var delay;

    // Hide main nav subnav if open
    if ($html.hasClass(subnavIsOpenClass)) {

      if (typeof closeMainNavCb === 'function') {
        closeMainNavCb();
        delay = 400;
      }
    }

    $modal.velocity(
      'slideDown',
      {
        delay: delay,
        easing: 'easeOutQuart',
        complete: function() {
          $modal.addClass('is-visible');
          $html.addClass('search-modal-is-open');

          $inner.velocity(
            {
              opacity: '1'
            },
            {
              duration: '200ms',
              complete: function() {
                $input.focus();
              }
            }
          );
        }
      }
    );
  }


  self.hideModal = function() {

    $inner.velocity(
      {
        opacity: '0'
      },
      {
        duration: '200ms'
      }
    );
    $modal.velocity(
      'slideUp',
      {
        easing: 'easeOutQuart',
        complete: function() {
          $modal.removeClass('is-visible');
          $html.removeClass('search-modal-is-open');
        }
      }
    );
  }


  self.clickOutsideHandler = function() {

    $(document).on('click', function(e) {

      if (! $(e.target).closest($modal).length) {

        if ($modal.hasClass('is-visible')) {
          self.hideModal();
        }
      }
    });
  }


  self.reset = function() {
    $html.removeClass('search-modal-is-open');
  }


  self.init = function() {
    self.clickHandler($open);
    self.clickHandler($close);
    self.clickOutsideHandler();
  }
};

module.exports = SearchModal;
