'use strict';

// Constructor
var Constants = function() {

  this.MEDIA_QUERIES = {
    'TINY'     :  '(min-width:  480px)',
    'SMALLER'  :  '(min-width:  640px)',
    'SMALL'    :  '(min-width:  768px)',
    'TABLET'   :  '(min-width:  900px)',
    'MEDIUM'   :  '(min-width: 1100px)',
    'LARGE'    :  '(min-width: 1200px)',
    'XXLARGE'  :  '(min-width: 1600px)'
  }

  this.RANDOM_ID = function() {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for ( var i=0; i < 5; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  this.ARIA_EXPANDED_TOGGLE = function($button, setAttr) {

    if ($button[0].hasAttribute('aria-expanded')) {

      if (typeof setAttr !== 'undefined') {

        var attr = $button.attr('aria-expanded');

        if (attr === 'false') {
          $button.attr('aria-expanded', true);
        } else {
          $button.attr('aria-expanded', false);
        }

      } else {

        $button.attr('aria-expanded', setAttr);
      }
    }
  }

  // Adapted from underscore:
  // https://github.com/jashkenas/underscore/blob/master/underscore.js#L810
  this.THROTTLE = function(func, wait, options) {
    var timeout, context, args, result;
    var previous = 0;
    var now = function() {
      return new Date().getTime();
    };
    if (!options) options = {};

    var later = function() {
      previous = options.leading === false ? 0 : now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };

    var throttled = function() {
      if (!previous && options.leading === false) previous = now();
      var remaining = wait - (now() - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now();
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };

    throttled.cancel = function() {
      clearTimeout(timeout);
      previous = 0;
      timeout = context = args = null;
    };

    return throttled;
  }

  this.MATCHMEDIA_HANDLER = function(mqName, matchFun, unmatchFun) {
    var mq = new Constants().MEDIA_QUERIES[mqName];
    var mql = window.matchMedia(mq);
    var mediaQueryHandler = function(mql) {
      if (mql.matches) {
        if (typeof matchFun === 'function') {
          matchFun();
        }
      } else {
        if (typeof unmatchFun === 'function') {
          unmatchFun();
        }
      }
    };
    mql.addListener(mediaQueryHandler);
    mediaQueryHandler(mql);
  }
};

module.exports = Constants;
