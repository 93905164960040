'use strict';

var Constants = require('../constants/constants');

// Constructor
var Hero = function() {

  var self = this;

  self.config = {
    'imageContainer'  :  $('.hero__slide-image'),
    'carousel'        :  $('#heroCarousel'),
    'slide'           :  $('.hero__slide'),
    'nav'             :  $('#heroNav'),
    'navButton'       :  $('.hero__nav-button'),
    'slickSettings'   :  {
      dots: true,
      dotsClass: 'hero__dots',
      easing: 'ease',
      autoplay: true,
      autoplaySpeed: 2300,
      centerMode: true,
      centerPadding: '0',
      swipe: false,
      speed: 700,
      arrows: false
    }
  }

  //var $imageContainer = self.config.imageContainer;
  var $carousel = self.config.carousel;
  //var $slide = self.config.slide;
  //var $nav = self.config.nav;
  var $navButton = self.config.navButton;
  var slickSettings = self.config.slickSettings;


  self.initCarousel = function() {
    $carousel.slick(slickSettings);
  }


  self.parseNavButtons = function() {

    $navButton.each(function() {

      var $this = $(this);
      var slideIndex = $this.attr('data-hero-slide-index');

      self.navClickHandler($this, slideIndex);
    });
  }


  self.navClickHandler = function($button, slideIndex) {

    $button.on('click', {slideIndex: slideIndex}, self.navClickCb);
  }


  self.navClickCb = function(event) {

    var slideIndex = event.data.slideIndex;

    $carousel.slick('slickGoTo', slideIndex);
  }


  self.syncNav = function() {

    var parseButtons = function(currentSlide) {

      $navButton.removeClass('is-active');
      $navButton.blur();
      $navButton.each(function() {

        var $this = $(this);
        var buttonIndex = parseInt($this.attr('data-hero-slide-index'));

        if (buttonIndex === currentSlide) {
          $this.addClass('is-active');
        }
      });
    }

    var currentSlide = $carousel.slick('slickCurrentSlide');

    parseButtons(currentSlide);

    $carousel.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      parseButtons(nextSlide);
    });
  }


  self.reset = function() {

    if ($carousel.hasClass('slick-initialized')) {
      $carousel.slick('unslick');
      $carousel.off('afterChange');
      $navButton.off('click', self.navClickCb);
      $navButton.removeClass('is-active');
    }
  }


  self.init = function() {

    var largeViewports = function() {

      if ($carousel.length > 0) {
        self.initCarousel();
        self.parseNavButtons();
        self.syncNav();
      }
    };

    var smallViewports = function() {
      self.reset();
    };

    new Constants().MATCHMEDIA_HANDLER('MEDIUM', largeViewports, smallViewports);
  }
};

module.exports = Hero;
