'use strict';

var Constants = require('../../constants/constants');
var hoverintent = require('hoverintent');

var DesktopSubnavs = function($item, $link, $subnav, $inner, $outer, subnavIsOpenClass) {

  var self = this;
  var $html = $('html');


  self.parseItems = function() {

    $item.each(function() {

      var $this = $(this);
      var $lnk = $this.find($link);
      var $sbnv = $this.find($subnav);
      var $innr = $this.find($inner);

      if (! Modernizr.touchevents) {
        self.hoverHandler($lnk, $sbnv, $innr);
      }

      self.focusHandler($lnk, $sbnv, $innr);
    });
  }


  self.hoverHandler = function($myLink, $mySubnav, $myInner) {

    var myLink = $myLink[0];
    var mq = new Constants().MEDIA_QUERIES.MEDIUM;
    var mql = window.matchMedia(mq);

    var hoverEvents = hoverintent(myLink, function(e) {

      self.subnavVisibility($myLink, $mySubnav, $myInner);

    }, function(){}).options({interval: 100});

    // Remove hoverintent listeners
    var mediaQueryHandler = function(mql) {

      if (! mql.matches) {
        hoverEvents.remove();
      }
    };

    mql.addListener(mediaQueryHandler);
    mediaQueryHandler(mql);
  }


  self.focusHandler = function($myLink, $mySubnav, $myInner) {

    $myLink.on('focus', function() {
      self.subnavVisibility($myLink, $mySubnav, $myInner);
    });
  }


  self.subnavVisibility = function($myLink, $mySubnav, $myInner) {

    var showMySubnav = function() {
      self.showSubnav($mySubnav, $myInner);
    };

    if ($subnav.hasClass('is-visible')) {
      self.hideCurrentSubnavs($mySubnav, showMySubnav);
    } else {
      showMySubnav();
    }

    $link.removeClass('is-active');
    $myLink.addClass('is-active');
  }


  self.hideCurrentSubnavs = function($currentSubnav, cb, lastTime) {

    var lastTime = lastTime || false;

    $subnav.not($currentSubnav).each(function() {

      var $this = $(this);

      if ($this.hasClass('is-visible')) {

        var $innr = $this.find($inner);

        self.hideSubnav($this, $innr, lastTime, cb);
      }
    });
  }


  self.mouseOutOuter = function() {

    var mq = new Constants().MEDIA_QUERIES.MEDIUM;
    var mql = window.matchMedia(mq);

    var hoverEvents = hoverintent($outer[0], function(e) {}, function(){
      self.hideCurrentSubnavs(null, null, true);
      $link.removeClass('is-active');
    });

    // Remove hoverintent listeners
    var mediaQueryHandler = function(mql) {

      if (! mql.matches) {
        hoverEvents.remove();
      }
    };

    mql.addListener(mediaQueryHandler);
    mediaQueryHandler(mql);
  }


  self.focusOutOuter = function() {

    var $lastLink = $outer.find('a').last();

    $lastLink.blur(function() {
      self.hideCurrentSubnavs(null, null, true);
    });
  }


  self.showSubnav = function($subnav, $inner) {

    if (! $inner.hasClass('velocity-animating')) {

      $html.addClass(subnavIsOpenClass);
      $subnav.addClass('is-visible');
      $subnav.css('z-index', '2');
      $inner
        .velocity(
          'slideDown',
          {
            display: 'inline-block',
            duration: '200ms'
          }
        )
        .velocity(
          {
            opacity: '1'
          },
          {
            duration: '100ms'
          }
        );
    }
  }


  self.hideSubnav = function($subnav, $inner, lastTime, cb) {

    var delay = lastTime === true ? '0' : '300ms';

    $html.removeClass(subnavIsOpenClass);
    $subnav.removeClass('is-visible');
    $subnav.css('z-index', '1');
    $inner
      .velocity(
        {
          opacity: '0'
        },
        {
          duration: '100ms',
          complete: function() {
            if (typeof cb === 'function') {
              cb();
            }
          }
        }
      )
      .velocity(
        'slideUp',
        {
          delay: delay,
          duration: '250ms'
        }
      );
  }


  self.reset = function() {

    var $lastLink = $outer.find('a').last();

    $link.off('focus');
    $link.removeClass('is-active');
    $subnav.removeClass('is-visible');
    $subnav.removeAttr('style');
    $inner.velocity('stop');
    $inner.removeAttr('style');
    $lastLink.off('blur');
  }


  self.init = function() {

    self.parseItems();
    self.mouseOutOuter();
    self.focusOutOuter();
  }
};

module.exports = DesktopSubnavs;
