'use strict';

var Constants = require('../constants/constants');
var DesktopSticky = require('./lib/desktop-sticky');
var DesktopSubnavs = require('./lib/desktop-subnavs');
var SearchModal = require('./lib/search-modal');
var NavVisibility = require('./lib/nav-visibility');
var MobileSubnavs = require('./lib/mobile-subnavs');
var DesktopHighlights = require('./lib/desktop-highlights');
var NavSoftScroll = require('./lib/nav-soft-scroll');

// Constructor
var MainHeader = function() {

  var self = this;

  self.config = {

    // Classes
    'navIsOpenClass'           :  'main-nav-is-open',
    'subnavIsOpenClass'        :  'main-nav-subnav-is-open',

    // Main header
    'header'                   :  $('.main-header'),

    // Main nav container
    'navContainer'             :  $('.main-header__main-nav-container'),
    'openNav'                  :  $('.main-header__open-nav'),
    'closeNav'                 :  $('.main-header__close-main-nav'),

    // Main nav
    'navItem'                  :  $('.main-nav__item'),
    'navLink'                  :  $('.main-nav__link'),
    'subnav'                   :  $('.main-nav__subnav'),
    'subnavInner'              :  $('.main-nav__subnav-inner'),
    'subnavLink'               :  $('.main-nav__subnav-link'),

    // Search modal
    'searchModal'              :  $('.main-header__search-modal'),
    'searchModalInner'         :  $('.main-header__search-modal-inner'),
    'searchModalInput'         :  $('.main-header__search-input'),
    'openSearchModal'          :  $('.main-header__open-search'),
    'closeSearchModal'         :  $('.main-header__close-search-modal'),

    // Main nav highlight
    'highlightContainer'       :  $('.main-nav__highlight'),
    'highlightContent'         :  $('.main-nav__highlight-content'),
    'highlightLinkedTemplate'  :  function(content) {

      var href = content.href;
      var template = '<div class="linked__item">';

      if (content.image) {
        template += '<div class="linked__image">' +
                      '<a href="' + href + '">' +
                        '<img src="' + content.image + '">'+
                      '</a>' +
                    '</div>';
      }

      // Open info tag
      template += '<div class="linked__info">';

      if (content.type || content.author) {
        template += '<div class="linked__info-top">';

        if (content.type) {
          template += '<div class="linked__type">' +
                        '<a href="' + content.typeHref + '">' + content.type + '</a>' +
                      '</div>';
        }

        if (content.author) {
          template += '<h4 class="linked__author">' +
                        '<a href="' + content.authorHref + '">' + content.author + '</a>' +
                      '</h4>';
        }

        // Close info top tag
        template += '</div>';
      }

      if (content.title) {
        template += '<div class="linked__info-middle">';

        template += '<h3 class="linked__title">' +
                      '<a href="' + href + '">' + content.title + '</a>' +
                    '</h3>';

        // Close info middle tag
        template += '</div>';
      }

      if (content.datetime) {
        template += '<div class="linked__info-bottom">';

        template += '<div class="linked__info-bottom">' +
                      '<time class="linked__datetime">' + content.datetime + '</time>' +
                    '</div>';

        // Close info bottom tag
        template += '</div>';
      }

      // Close info tag + linked element tag
      template += '</div></div>';

      return template;
    }
  }


  self.init = function() {

    var closeDesktopSubnav = function() {
      new DesktopSubnavs().hideSubnav(self.config.subnav, self.config.subnavInner, true);
    };

    new SearchModal(
     self.config.openSearchModal,
     self.config.closeSearchModal,
     self.config.searchModal,
     self.config.searchModalInner,
     self.config.searchModalInput,
     self.config.subnavIsOpenClass,
     closeDesktopSubnav
    ).init();

    new DesktopSticky(self.config.header).init();

    var largeViewports = function() {

      new DesktopSubnavs(
        self.config.navItem,
        self.config.navLink,
        self.config.subnav,
        self.config.subnavInner,
        self.config.header,
        self.config.subnavIsOpenClass
      ).init();

      new DesktopHighlights(
        self.config.navItem,
        self.config.highlightContainer,
        self.config.highlightContent,
        self.config.highlightLinkedTemplate
      ).init();

      new NavVisibility(
        self.config.navContainer,
        self.config.openNav,
        self.config.closeNav
      ).reset();

      new MobileSubnavs(
        self.config.navItem,
        self.config.navLink,
        self.config.subnav,
        self.config.subnavInner
      ).reset();
    };

    var smallViewports = function() {

      new DesktopSubnavs(
        self.config.navItem,
        self.config.navLink,
        self.config.subnav,
        self.config.subnavInner,
        self.config.header,
        self.config.subnavIsOpenClass
      ).reset();

      new NavVisibility(
        self.config.navContainer,
        self.config.openNav,
        self.config.closeNav
      ).init();

      new MobileSubnavs(
        self.config.navItem,
        self.config.navLink,
        self.config.subnav,
        self.config.subnavInner
      ).init();
    };

    new NavSoftScroll(self.config).init();

    new Constants().MATCHMEDIA_HANDLER('MEDIUM', largeViewports, smallViewports);
  }
};

module.exports = MainHeader;
