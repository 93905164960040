'use strict';

var Masonry = require('masonry-layout');
var imagesLoaded = require('imagesloaded');
var Constants = require('../constants/constants');

// Constructor
var MasonryGrid = function() {

  var self = this;

  self.config = {
    'grid'       :  $('[data-masonry-grid]'),
    'gridItem'   :  '[data-masonry-item]',
    'gridImage'  :  '[data-masonry-item] img'
  }

  var $grid = self.config.grid;
  var gridItem = self.config.gridItem;
  var gridImage = self.config.gridImage;
  var masonry;


  self.initMasonry = function() {

    masonry = new Masonry($grid[0], {
      itemSelector: gridItem
    });
  }


  self.reset = function() {

    if (typeof masonry !== 'undefined') {
      masonry.destroy();
    }
  }


  self.init = function() {
    if ($grid.length > 0) {

      var largeViewports = function() {
        imagesLoaded(gridImage, self.initMasonry);
      };

      var smallViewports = function() {
        self.reset();
      };

      new Constants().MATCHMEDIA_HANDLER('TINY', largeViewports, smallViewports);
    }
  }
};

module.exports = MasonryGrid;
