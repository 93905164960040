'use strict';

var Constants = require('../../constants/constants');

var NavVisibility = function($nav, $open, $close) {

  var self = this;
  var $html = $('html');


  self.clickHandler = function($button) {

    $button.on('click', new Constants().THROTTLE(function() {
      self.visibility();
    }, 300));
  }


  self.visibility = function() {

    if ($nav.hasClass('is-visible') && !$nav.hasClass('velocity-animating')) {
      self.closeNav();
    } else if (!$nav.hasClass('velocity-animating')) {
      self.openNav();
    }
  }


  self.openNav = function() {

    $nav.addClass('is-visible');
    $html.addClass('no-scroll main-nav-is-open');
    $nav.velocity(
      {
        translateY: ['0', '-100%']
      },
      {
        duration: 800,
        easing: 'easeOutQuart',
        display: 'flex'
      }
    );
  }


  self.closeNav = function() {

    $nav.removeClass('is-visible');
    $html.removeClass('no-scroll main-nav-is-open');
    $nav.velocity(
      {
        translateY: ['-100%', '0']
      },
      {
        easing: 'easeOutQuart',
        display: 'none'
      }
    );
  }


  self.reset = function() {
    $nav.removeAttr('style');
    $nav.removeClass('is-visible');
  }


  self.init = function() {
    $nav.css('will-change', 'transform');
    self.clickHandler($open);
    self.clickHandler($close);
  }
};

module.exports = NavVisibility;
