'use strict';

var Constants = require('../constants/constants');
var hoverintent = require('hoverintent');

// Constructor
var Claim = function() {

  var self = this;
  var $window = $(window);

  self.config = {
    'claim'       :  $('.claim'),
    'claimInner'  :  $('.claim__container'),
    'show'        :  document.querySelector('.main-header__brand'),
    'cursor'      :  document.querySelector('.claim__cursor')
  }

  var $claim = self.config.claim;
  var $claimInner = self.config.claimInner;
  var show = self.config.show;
  var cursor = self.config.cursor;


  self.hoverHandler = function() {

    var mq = new Constants().MEDIA_QUERIES.MEDIUM;
    var mql = window.matchMedia(mq);

    var hoverEvents = hoverintent(show, function() {
      self.showClaim();
    }, function(){}).options({interval: 700});

    var mediaQueryHandler = function(mql) {

      if (! mql.matches) {
        hoverEvents.remove();
      }
    };

    mql.addListener(mediaQueryHandler);
    mediaQueryHandler(mql);
  }


  self.showClaim = function() {

    $claim.velocity(
      {
        translateY: ['0', '-100%']
      },
      {
        easing: 'easeOutQuart',
        duration: 1200,
        complete: function() {
          $claim.addClass('is-visible');
        }
      }
    );
    $claimInner.velocity(
      {
        opacity: 1
      },
      {
        delay: 800
      }
    );
  }


  self.claimClickHandler = function() {

    $claim.on('click', function() {
      self.hideClaim();
    });
  }


  self.hideClaim = function() {

    $claimInner.velocity(
      {
        opacity: 0
      }
    );
    $claim.velocity(
      {
        translateY: ['-100%', '0']
      },
      {
        easing: 'easeOutQuart',
        complete: function() {
          $claim.removeClass('is-visible');
        }
      }
    );
  }



  self.cursorHandler = {

    'enable': function() {
      var positionElement = function(event) {
        var mouse;
        mouse = {
          x: event.clientX,
          y: event.clientY
        };
        cursor.style.top = mouse.y + 'px';
        cursor.style.left = mouse.x + 'px';
      }

      $window.on('mousemove.claimCursor', function(event) {
        setTimeout(function() {
          positionElement(event);
        }, 1);
      });
    },

    'disable': function() {
      $window.off('mousemove.claimCursor');
    }
  }


  self.init = function() {
    self.claimClickHandler();

    var largeViewports = function() {
      self.hoverHandler();
      self.cursorHandler.enable();
    };

    var smallViewports = function() {
      self.cursorHandler.disable();
    };

    new Constants().MATCHMEDIA_HANDLER('MEDIUM', largeViewports, smallViewports);
  }
};

module.exports = Claim;
