'use strict';

var Constants = require('../../constants/constants');
var DesktopSubnavs = require('./desktop-subnavs');
var NavVisibility = require('./nav-visibility');

var NavSoftScroll = function(config) {

  var self = this;
  var $link = config.subnavLink;
  var $desktopSubnav = config.subnav;
  var $desktopSubnavInner = config.subnavInner;
  var $mobileNav = config.navContainer;


  self.checkHash = function() {

    $link.each(function() {
      var $this = $(this);
      var href = $this.attr('href');

      if (href.indexOf('#') > -1) {
        var str = href.split('#', 2);
        var $target = $('#' + str[1]);

      /*  $this.on('click', function(e) {
          e.preventDefault();
          $link.removeClass('is-current');
          $this.addClass('is-current');
          self.scrollAnimation($target);
          self.closeNavs();
          location.hash = '#' + str[1];
        });
      }*/

        if ($target.length) {
          $this.on('click', function(e) {
            e.preventDefault();
            $link.removeClass('is-current');
            $this.addClass('is-current');
            self.scrollAnimation($target);
            self.closeNavs();
            location.hash = '#' + str[1];
          });
        }
      }
    });
  }


  self.closeNavs = function() {

    var desktopNavs = function() {
      new DesktopSubnavs().hideSubnav($desktopSubnav, $desktopSubnavInner, true);
      $('html').removeClass('main-nav-subnav-is-open');
    };

    var mobileNavs = function() {
      new NavVisibility($mobileNav).closeNav();
      $('html').removeClass('no-scroll main-nav-is-open');
    };

    new Constants().MATCHMEDIA_HANDLER('MEDIUM', desktopNavs, mobileNavs);
  }


  self.scrollAnimation = function($target) {

    $target.velocity(
      'scroll',
      {
        duration: 1100,
        easing: 'easeOutQuart',
        offset: -92
      }
    );
  }


  self.init = function() {
    self.checkHash();
  }
};

module.exports = NavSoftScroll;
