'use strict';

var Constants = require('../constants/constants');

// Constructor
var StickySidebar = function() {

  var self = this;

  self.config = {

    'sidebar'        :  $('[data-sticky-sidebar]'),
    'defaultOffset'  :  110
  }

  var $sidebar       =  self.config.sidebar,
      defaultOffset  =  self.config.defaultOffset


  self.StickyOptions = function() {

    this.offset_top = defaultOffset;
  }


  self.stickSidebar = function() {

    $sidebar.each(function(i) {

      var $this = $(this),
          options = new self.StickyOptions()

      if ($this.attr('data-sticky-parent')) {

        var parent = $this.attr('data-sticky-parent');

        options.parent = parent;
      }

      if ($this.attr('data-sticky-offset')) {

        var offset = parseInt($this.attr('data-sticky-offset'));

        options.offset_top = offset;
      }

      $this.stick_in_parent(options);
    });
  }


  self.unstickSidebar = function() {

    $sidebar.trigger('sticky_kit:detach');
  }


  self.matchMediaHandler = function() {

    var mq = new Constants().MEDIA_QUERIES.TABLET,
        mql = window.matchMedia(mq);

    mql.addListener(self.mediaQueryHandler);

    self.mediaQueryHandler(mql);
  }


  self.mediaQueryHandler = function(mql) {

    if (mql.matches) {

      self.stickSidebar();
      self.forceRecalc();
    } else {

      self.unstickSidebar();
    }
  }


  self.forceRecalc = function() {

    $sidebar.each(function() {

      var $this = $(this);

      $this.trigger('sticky_kit:recalc');
    });
  }


  self.init = function() {

    self.matchMediaHandler();
  }
};

module.exports = StickySidebar;
