'use strict';

var Photoswipe = require('photoswipe');
var PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default.js');

// Constructor
var Pswp = function() {

  var self = this;

  self.config = {
    'image'         :  $('[data-pswp-image] > img'),
    'imgContainer'  :  $('[data-pswp-image]'),
    'iconTemplate'  :  '<div class="pswp__open-gallery-icon"><span class="icon icon--gallery"></span></div>'
  }

  var $image = self.config.image;
  var $imgContainer = self.config.imgContainer;
  var configArr = [];
  var pswpElement = document.querySelectorAll('.pswp')[0];
  var iconTemplate = self.config.iconTemplate;


  self.makeConfigArr = function() {

    $image.each(function(index) {

      var $this = $(this);
      var imgSrc = $this[0].currentSrc || $this[0].src;
      var placeholder = imgSrc;
      var newImg = new Image();
      var obj = {};

      $this.attr('data-gallery-index', index);

      newImg.src = imgSrc;

      newImg.onload = function() {

        obj.src = imgSrc;
        obj.msrc = placeholder;
        obj.w = newImg.naturalWidth;
        obj.h = newImg.naturalHeight;
        obj.index = index;

        configArr.push(obj);
      }
    });
  }


  self.clickHandler = function() {

    var initPhotoswipe = function(index) {

      var options = {

        index: index,
        getThumbBoundsFn: function(index) {

            // find thumbnail element
            var thumbnail = document.querySelectorAll('[data-pswp-image]')[index];

            // get window scroll Y
            var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            // optionally get horizontal scroll

            // get position of element relative to viewport
            var rect = thumbnail.getBoundingClientRect();

            // w = width
            return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
        },
        history: false,
        zoomEl: false,
        barsSize: {top: 100, bottom: 100}
      };

      var gallery = new Photoswipe(pswpElement, PhotoSwipeUI_Default, configArr, options)
      gallery.init();
    };


    $imgContainer.on('click', function() {

      var $this = $(this);
      var $img = $this.find($image);
      var currItem = parseInt($img.attr('data-gallery-index'));

      configArr.sort(function(a, b) {
        return a.index - b.index;
      });

      initPhotoswipe(currItem);
    });
  }


  self.appendIcons = function() {

    $imgContainer.each(function() {

      var $this = $(this);

      $this.append(iconTemplate);
    });
  }


  self.init = function() {

    self.appendIcons();

    $(window).load(function() {

      self.makeConfigArr();
      self.clickHandler();
    });
  }
};

module.exports = Pswp;
